<template>
    <div class="code-input">
        <el-dialog :visible.sync="dialogVisible" width="600px">


            <el-image src="/web/image/logo0.png" style="width:100px;position: absolute; top:20px;left:-20px"></el-image>
            <div class="padding" style="background: #fff;border-radius: 20px;border: 2px solid #e6a13c ;">
                <div class="center" style="position: relative;bottom: 40px;">
                    <el-tag effect="dark" type="primary" class="padding tip relative">
                        <h2 class="lt">{{ type == 1 ? $t('VIP历史记录') : $t('VIP会员兑换') }}</h2>
                        <el-image src="/web/image/vip1.png"
                            style="position: absolute;left:-25px;top:-30px;transform: rotate(-45deg);"></el-image>
                    </el-tag>
                </div>


                <div v-if="type == 0">

                    <div class="mt margin-x">{{ $t('请输入激活码') }}</div>

                    <div class="margin-t margin-x"><el-input :maxlength="19" @input="formatCode"
                            :placeholder="$t('请输入激活码')" v-model="code">
                            <i slot="suffix" v-if="code" class="el-icon-circle-close el-input__icon pointer"
                                @click="code = ''" style="color:#333;"></i>

                        </el-input></div>

                    <div class="center margin-lt">
                        <el-button @click="submit" type="primary" round>{{ $t('激活') }}</el-button>
                    </div>

                    <div class="st margin-t center">{{ $t('提醒：加海澳微信即可有机会领取7天VIP，每个微信号可领取一次') }}</div>
                </div>

                <div v-else>
                    <el-table :data="list" style="width: 100%">
                        <el-table-column prop="record_time" :label="$t('时间')" width="180px">
                        </el-table-column>
                        <el-table-column prop="days" :label="$t('时长')" width="100px">
                        </el-table-column>
                        <el-table-column prop="content" :label="$t('方式')">
                            <template slot-scope="scope">
                                <div v-if="scope.row.vip_id == 0" class="st">
                                    {{ $t('兑换码') }}：{{ scope.row.out_trade_no }}
                                </div>
                                <div class="st1" v-else>
                                    {{ $t(scope.row.pay_type == 2 ? '支付宝' : '微信支付') }}
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>


        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "vipcodeDialog",
    data() {
        return {
            dialogVisible: false,
            code: "",
            type: 0,
            list: []
        };
    },
    props: {
    },
    methods: {
        show(type) {
            this.type = type;
            this.code = "";
            if (this.type == 1) {
                this.query();
            }
            this.dialogVisible = true;
        },

        formatCode() {
            this.code = this.code.replace(/\D/g, '').replace(/(\d{4})(?=\d)/g, "$1 ");
        },


        query() {
            this.$http.post("/api/vip/record/list").then((list) => {
                this.list = list;
            })
        },

        submit() {
            if (!this.code) return;
            this.$http.post("/api/vip/code", { code: this.code.replaceAll(" ", "") }).then((res) => {
                if (res) {
                    this.$store.dispatch("fetch_user");
                    this.$message({
                        message: this.$t('兑换成功'),
                        type: 'success'
                    });
                    this.dialogVisible = false;

                }
            })
        }
    },
};
</script>
<style scoped>
.code-input>>>.el-input__inner::placeholder {
    color: #999;
}

.code-input {
    border-radius: 20px;
    border: 1px solid #e6a13c;
}

.code-input>>>.el-input__inner {
    background-color: #ccc;
    border-radius: 20px;
    color: #333;
}

.code-input>>>.el-input__suffix:hover {
    border-color: #333 !important;
}

.code-input>>>.el-dialog {
    background: transparent;
}

.code-input>>>.el-dialog__headerbtn .el-dialog__close {
    color: #fff;
    font-size: 30px;
}
</style>
